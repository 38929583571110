import React, { useState } from "react";
import { CollapseMenuIcon, ExpandMenuIcon } from "../images/nigeria/Icons";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import styled from "styled-components";

const FaqItem = ({ title, description, open }) => {
    const [isOpen, setIsOpen] = useState(open || false);
    const toggleIsOpen = () => setIsOpen((value) => !value);
    return (
        <FAQItemContainer>
            <div className="text-section">
                <div className="title" onClick={toggleIsOpen}>
                    <span> <Trans>{title}</Trans></span>
                    <div className={`description ${isOpen && "open"}`}>
                        <Trans>{description}</Trans>
                    </div>
                </div>

            </div>
            {/* <div className="toggle-button" onClick={toggleIsOpen}> */}
            {isOpen ? <CollapseMenuIcon size={20} /> : <ExpandMenuIcon size={20} />}
            {/* </div> */}
        </FAQItemContainer>
    );
};
export default FaqItem


export const FaqGroup = ({ list }) => {
    const [active, setActive] = useState(null);
    const toggleActive = (item) => {
        console.log(item, active);
        setActive((value) => value?.title !== item.title ? item : null)
    };

    return (
        <>
            {list.map((item, index) => (
                <FAQItemContainer key={index}>
                    <div className="text-section">
                        <div className="title" onClick={() => toggleActive(item)}>
                            <span> <Trans>{item.title}</Trans></span>
                            {/* <div className="toggle-button" onClick={() => toggleActive(item)}> */}
                            {active?.title === item.title ? <CollapseMenuIcon size={20} /> : <ExpandMenuIcon size={20} />}
                            {/* </div> */}
                        </div>
                        <div className={`description ${active?.title === item.title && "open"}`}>
                            <Trans>{item.description}</Trans>
                        </div>
                    </div>
                </FAQItemContainer>
            ))}
        </>
    )

}


const FAQItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    position: relative;
    width: 100%;
  
    * {
        transition: all 0.3s ease;
    }

  
  
    .text-section {
        margin-bottom: 8px;
      .title {
        font-family: "Founders Grotesk";
        font-size: 28px;
        font-weight: 500;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(0, 0, 0, 1);
        margin-bottom: 0px;
        cursor: pointer;
        width: 100%;
        max-width: 100vw;

        display: flex;
        align-items: center;
        justify-content: space-between;


        .toggle-button {
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
      }
  
      .description {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 0px;
        /* display: none; */
        position: absolute;
        opacity: 0;
  
        &.open {
          /* display: block; */
          margin-top: 8px;
          position: relative;
        opacity: 1;
        }
      }
    }
  
    @media only screen and (max-width: 768px) {
      gap: 26px;
  
      .text-section {
      }
    }
  `;